import { EnvironmentInterface } from '../definitions/environment.interface';
import { commonEnv } from './environment.common';

export const baseAWSUrl = 'https://euapps-dev-uploads.s3.eu-west-1.amazonaws.com';

const env = {
  name: 'dev',
  production: false,
  apiPath: 'api',
  apiSecurityDomain: 'eurodev.bi4dev.com',
  httpSecure: true,
  universitySecurityApiDomain: 'root.dev.educa.university',
  backofficeDomain: 'https://dev.backoffice.mylxp.ai',

  // Sentry settings
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.25,
  replaysOnErrorSampleRate: 1.0,
  showDialog: false,
  privacyPolicy: {
    es: `${baseAWSUrl}/assets/privacy_policy_es.pdf`,
    en: `${baseAWSUrl}/assets/privacy_policy_en.pdf`,
    it: `${baseAWSUrl}/assets/privacy_policy_it.pdf`
  },
  enrolmentConditions: {
    es: `${baseAWSUrl}/assets/enrolment_conditions_es.pdf`,
    en: `${baseAWSUrl}/assets/enrolment_conditions_en.pdf`,
    it: `${baseAWSUrl}/assets/enrolment_conditions_it.pdf`
  }
};

export const environment: EnvironmentInterface = Object.assign(commonEnv, env);
